import { MainLayout } from '../../layout/mainLayout';
import React, { useState } from 'react';
import { usePageParams } from '../../layout/appContext';
import PageError, { PageErrorContent } from '../pageError';
import { useAsync, UseAsyncReturn } from 'react-async-hook';
import {
  ShClientBillingViewInvoiceResult,
  ShFrontBillingInvoiceAPI,
  ShFrontBillingViewInvoiceResult,
} from '@shoootin/api';
import {
  ShButtonLink,
  ShInvoiceElements,
  ShInvoiceFooter,
  ShInvoiceHeader,
  ShInvoicePayment,
  ShInvoicePaymentInformations,
  ShInvoiceTotals,
  ShSpinner,
  ShTitle,
  useShInvoicePayment,
  useStripeLoader,
} from '@shoootin/components-web';
import {
  Elements,
  injectStripe,
  ReactStripeElements,
  StripeProvider,
} from 'react-stripe-elements';
import { ShText, useShIntlLocale } from '@shoootin/translations';
import { isDev } from 'appEnv';
import AppSection from '../../primitives/appSection';

import { ShMoment } from '@shoootin/utils';
import { ShColors, ShFonts } from '@shoootin/design-tokens';
import { AppSEOTitle } from '../../appSEO';

const InvoiceLoading = () => {
  return (
    <div css={{ padding: 150 }}>
      <ShSpinner size={'m'} />
    </div>
  );
};

const InvoiceModalPayable = ({
  invoiceResult,
  stripe,
  refresh,
}: {
  invoiceResult: ShClientBillingViewInvoiceResult;
  stripe?: ReactStripeElements.StripeProps;
  refresh?: () => void;
}) => {
  const {
    invoicePaymentState: {
      paymentMethodType,
      paymentErrors,
      savedCreditCard,
      selectedPaymentMethodId,
    },
    invoicePaymentAPI: {
      selectPaymentMethod,
      deleteCreditCard,
      setPaymentErrors,
      validateFrontInvoicePayment,
      selectCreditCard,
      selectACH,
    },
  } = useShInvoicePayment(invoiceResult.invoice, refresh);

  const showACH = invoiceResult.invoice.currency === 'USD';

  const [name, setName] = useState(invoiceResult.defaultName);
  const [email, setEmail] = useState(invoiceResult.defaultEmail);

  const onValidateClick = async () => {
    const payWithNewCreditCard = !selectedPaymentMethodId;

    console.log('validateSelectionWithPayment', {
      payWithNewCreditCard,
      paymentMethodType,
    });

    if (payWithNewCreditCard) {
      if (paymentMethodType === 'CREDIT_CARD') {
        const createTokenResult = await stripe!.createPaymentMethod('card');
        if (createTokenResult.paymentMethod) {
          console.debug(
            'Will pay with stripe paymentMethod',
            createTokenResult.paymentMethod,
          );
          await validateFrontInvoicePayment({
            paymentMethodType,
            paymentMethodId: createTokenResult.paymentMethod.id,
            stripeElements: stripe,
            saveCreditCard: savedCreditCard,
            payWithNewCreditCard: true,
          });
        } else {
          setPaymentErrors(createTokenResult?.error?.message);
          console.error(
            'Unable to create stripe paymentMethod',
            createTokenResult,
          );
        }
      } else if (paymentMethodType === 'ACH') {
        await validateFrontInvoicePayment({
          paymentMethodType,
          stripeElements: stripe!,
          paymentMethodId: undefined,
          payWithNewCreditCard,
          name,
          email,
        });
      }
    } else {
      // paid with saved payment method
      console.debug(
        `Will pay with stripe saved ${paymentMethodType}`,
        selectedPaymentMethodId,
      );
      if (paymentMethodType === 'CREDIT_CARD') {
        await validateFrontInvoicePayment({
          paymentMethodType,
          stripeElements: stripe,
          paymentMethodId: selectedPaymentMethodId,
          payWithNewCreditCard: false,
        });
      } else if (paymentMethodType === 'ACH') {
        await validateFrontInvoicePayment({
          paymentMethodType,
          paymentMethodId: selectedPaymentMethodId,
          payWithNewCreditCard: false,
          paymentIntentId: undefined,
        });
      }
    }
  };

  return (
    <ShInvoicePayment
      selectPaymentMethod={selectPaymentMethod}
      paymentMethodType={paymentMethodType}
      cards={invoiceResult.cards}
      achList={invoiceResult.achList}
      onValidateClick={onValidateClick}
      selectedPaymentMethodId={selectedPaymentMethodId}
      deleteCreditCard={deleteCreditCard}
      paymentErrors={paymentErrors}
      setPaymentError={setPaymentErrors}
      saveCreditCard={savedCreditCard}
      canSaveCreditCard={false}
      selectCreditCard={selectCreditCard}
      selectACH={selectACH}
      isDev={isDev}
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      showACH={showACH}
    />
  );
};

const InvoiceLoaded = ({
  invoiceResult,
  stripe,
  refresh,
}: {
  invoiceResult: ShFrontBillingViewInvoiceResult;
  stripe?: ReactStripeElements.StripeProps;
  refresh: () => void;
}) => {
  const payable =
    !invoiceResult.invoice.paid && invoiceResult.invoice.totalWithoutTax > 0;

  return (
    <div css={{ paddingTop: 50 }}>
      <AppSEOTitle title={`Invoice ${invoiceResult.invoice.invoiceNb}`} />
      <AppSection
        className="left photograph-form"
        header={
          <div
            css={{
              padding: 20,
              paddingBottom: 40,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div>
                <ShTitle size={4}>
                  <ShText message={'common_invoices_invoice'} />{' '}
                  {invoiceResult.invoice.invoiceNb}{' '}
                  {invoiceResult.invoice.paymentState &&
                    invoiceResult.invoice.paymentState.map(
                      (state: string, index: number) => (
                        <span
                          key={index}
                          css={{
                            fontSize: '80%',
                            paddingLeft: 10,
                            color: !invoiceResult.payable
                              ? ShColors.base
                              : ShColors.error,
                            fontFamily: !invoiceResult.payable
                              ? ShFonts.primary
                              : ShFonts.secondary,
                          }}
                        >
                          {state}
                        </span>
                      ),
                    )}
                </ShTitle>
              </div>
              {invoiceResult.payable && (
                <div css={{ paddingTop: 10 }}>
                  {invoiceResult.invoice.isLate ? (
                    <ShText
                      message={'common_invoices_dueDateLate'}
                      values={{
                        date: (
                          <b>
                            {ShMoment(invoiceResult.invoice.dueDate).format(
                              'DD MMMM YYYY',
                            )}
                          </b>
                        ),
                      }}
                    />
                  ) : (
                    <ShText
                      message={'common_invoices_dueDateOnTime'}
                      values={{
                        date: (
                          <b>
                            {ShMoment(invoiceResult.invoice.dueDate).format(
                              'DD MMMM YYYY',
                            )}
                          </b>
                        ),
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div>
              {invoiceResult.payable && (
                <ShButtonLink
                  href={invoiceResult.downloadUrl}
                  target={'_blank'}
                >
                  <ShText message={'common_actions_download'} />
                </ShButtonLink>
              )}
            </div>
          </div>
        }
      >
        {invoiceResult.payable && (
          <div>
            <div css={{ padding: 20 }}>
              <div
                css={{ padding: 30, boxShadow: '0 6px 12px rgba(0,0,0,0.175)' }}
              >
                <div>
                  <ShInvoiceHeader invoice={invoiceResult.invoice} />
                  <ShInvoiceElements
                    invoice={invoiceResult.invoice}
                    payable={payable}
                    collapsible={true}
                  />
                  <ShInvoiceTotals
                    totalWithoutTax={invoiceResult.invoice.totalWithoutTax}
                    totalTax={invoiceResult.invoice.totalTax}
                    totalWithTax={invoiceResult.invoice.totalWithTax}
                    currency={invoiceResult.invoice.currency}
                  />
                  <ShInvoicePaymentInformations
                    accountInformations={
                      invoiceResult.invoice.accountInformations
                    }
                    invoiceNb={invoiceResult.invoice.invoiceNb}
                  />
                  <ShInvoiceFooter
                    footerInformations={
                      invoiceResult.invoice.footerInformations
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              {payable && (
                <InvoiceModalPayable
                  invoiceResult={invoiceResult}
                  refresh={refresh}
                  stripe={stripe}
                />
              )}
            </div>
          </div>
        )}
      </AppSection>
    </div>
  );
};

const InvoiceDisplay = ({
  invoiceResult,
  refresh,
}: {
  invoiceResult: ShFrontBillingViewInvoiceResult;
  refresh: () => void;
}) => {
  const stripe = useStripeLoader(invoiceResult.stripeKey);
  const locale = useShIntlLocale();

  return (
    <StripeProvider stripe={stripe || null}>
      <Elements locale={locale}>
        <InvoiceLoadedWithStripe
          invoiceResult={invoiceResult}
          refresh={refresh}
        />
      </Elements>
    </StripeProvider>
  );
};

const InvoicePage = () => {
  const { invoiceId } = usePageParams();

  if (!invoiceId) {
    return <PageError />;
  }

  const asyncInvoice: UseAsyncReturn<any> = useAsync(async () => {
    return ShFrontBillingInvoiceAPI.getInvoice(invoiceId);
  }, [invoiceId]);

  return (
    <MainLayout
      className="light"
      showContact={false}
      pageName="home"
      showFooter={false}
    >
      {asyncInvoice.loading && <InvoiceLoading />}
      {asyncInvoice.error && <PageErrorContent message={'Invoice not found'} />}
      {asyncInvoice.result && (
        <InvoiceDisplay
          invoiceResult={asyncInvoice.result}
          refresh={asyncInvoice.execute}
        />
      )}
    </MainLayout>
  );
};

const InvoiceLoadedWithStripe = injectStripe(InvoiceLoaded);

export default InvoicePage;
